import {HttpHeaders} from '@angular/common/http'
import {preventBgoProprietaryHttpInterceptorHeader} from '../http/prevent.interceptor'
import {ApolloLink} from '@apollo/client/core'
import {HttpLink} from 'apollo-angular/http'
import {ConfigService} from '../config/config.service'
import {print, stripIgnoredCharacters} from 'graphql'
import {CMS_GRAPHQL_CLIENT_NAME} from '../content/content-graphql-split'

export function linkFactory(configService: ConfigService, httpLink: HttpLink, authLink?: ApolloLink): ApolloLink {
  const buildigoApi = buildigoGraphqlApiLink(configService, httpLink, authLink)
  const datoCmsApi = datoCmsApiLink(configService, httpLink)

  // split traffic between CMS and our backend API depending on the client name
  const link = ApolloLink.split(
    op => [CMS_GRAPHQL_CLIENT_NAME].includes(op.getContext().clientName),
    datoCmsApi,
    buildigoApi,
  )

  return link
}

function buildigoGraphqlApiLink(configService: ConfigService, httpLink: HttpLink, authLink?: ApolloLink): ApolloLink {
  const buildigoApi = httpLink.create({
    uri: configService.config.apiUrl,
    headers: new HttpHeaders({
      // manually set these headers for this link, because other links may not accept these headers in their CORS policy
      'apollographql-client-name': 'bgo-web-ui',
      'apollographql-client-version': configService.config.version,
    }),
    // override default behavior (print) to strip out unnecessary characters, makes queries smaller
    operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
  })
  let buildigoLink: ApolloLink[] = [buildigoApi]
  if (authLink) {
    buildigoLink = [authLink, buildigoApi]
  }

  return ApolloLink.from(buildigoLink)
}

function datoCmsApiLink(configService: ConfigService, httpLink: HttpLink) {
  const {apiToken, preview, environment} = configService.config.datocms
  return httpLink.create({
    uri: `https://graphql.datocms.com/`,
    headers: new HttpHeaders({
      ...preventBgoProprietaryHttpInterceptorHeader,
      Authorization: `Bearer ${apiToken}`,
      ...(preview ? {'X-Include-Drafts': 'true'} : undefined),
      ...(environment ? {'X-Environment': environment} : undefined),
    }),
    // override default behavior (print) to strip out unnecessary characters, makes queries smaller
    // this helps with APIs query size limit
    // https://www.contentful.com/developers/docs/references/graphql/#/introduction/query-size-limits
    // https://www.apollographql.com/docs/react/networking/advanced-http-networking/
    operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
  })
}
